.customPanel {
    padding: 20px 15px;
}

.ftb button {
    width: 50%;
    margin: 0px 10px;
}

.ftb {
    display: flex;
}
.endereço {
    margin: 0px 10px;
}
.buttonftb {
    border-radius: 25px !important;
}
.mapa{
    height: 600px;
}
.swal-text {
    font-size: 20px;
    font-weight: 600;
}
img.iconWaze {
    width: 35px;
    float: right;
    margin-top: -15px;
    border-radius: 25px;
}