.cardlogin{
    width: 90%;
    max-width:500px;
    background-color: #fff;
    margin: auto;
    margin-top: 20vh;
    border-radius: 20px;
}
.contentLogin{
    padding: 25px;
}
.w100 {
    width: 100%;
    display: flex;
}
.iconeLogin {
    margin-right: 10px;
    margin-bottom: -15px;
}
.contentLogin div {
    margin-bottom: 8px;
}
.btnLogin {
    width: 100%;
    border-radius: 20px !important;
}