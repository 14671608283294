.aceitarBtn {
    border: none !important;
    border-radius: 20px !important;
    font-size: 15px;
    float: right !important;
    padding: 4px 10px;
    background-color: #ffe57f !important;
}
.entregaBtn{
    border-radius: 25px !important;
    float:right;
    color:white !important;
    background-color: #3f51b5  !important;
}
.Pedido {
    max-width: 80%;
    margin: auto;
    -webkit-box-shadow: 0px 0px 7px 3px rgb(0 0 0 / 25%);
    -moz-box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 7px 3px rgb(0 0 0 / 25%);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 15px;
    display: flow-root;
}
.btnSair{
    margin: auto !important;
    margin-right: 0px !important;
}
.swal-text {
    font-size: 20px;
    font-weight: 600;
}